import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import "tom-select/dist/css/tom-select.bootstrap5"

export default class extends Controller {
  connect() {
    this.element.setAttribute("autocomplete", "none")

    const config = {
      valueField: "id",
      labelField: "name",
      searchField: ["id", "name", "content_preview"],
      selectOnTab: true,
      openOnFocus: true,
      placeholder: "Start typing to search for a page...",
      maxItems: 1,
      closeAfterSelect: true,
      load: this.loadOptions,
      render: {
        option: this.renderOption,
        item: this.renderItem
      }
    }

    new TomSelect(this.element, config)
  }

  renderItem(page, escape) {
    return `<div>${escape(page.id)} - ${escape(page.name)}</div>`
  }

  renderOption(page, escape) {
    return `
    <div>
      ${escape(page.id)} - ${escape(page.name)}<br><small><i>${escape(page.content_preview)}</i><small>
    </div> `
  }

  loadOptions(query, callback) {
    const url = `${this.input.dataset.url}?search=${encodeURIComponent(query)} `

    fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json)
      }).catch(() => {
        callback()
      })
  }
}


